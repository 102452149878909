import React from "react";
import { Stack, Typography } from "@mui/material";
import CardsGridContainer from "../../../../molecules/cardsGridContainer";
import CardsGridItem from "../../../../molecules/cardsGridItem";
import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import { useLocation } from "react-router-dom";

export default function TransactionStatusCardsGrid() {
  const url = `${process.env.REACT_APP_BASE_URL}/mcs/total-transaction`;
  const codeBroker = "MCS-001";

  // Search Params
  const { search: queryParams } = useLocation();
  const query = new URLSearchParams(queryParams);

  // Retrieve status from query params
  const status = query.get("status");

  async function fetchTotalTransaction(status: string) {
    const res = await axios.get(url, {
      params: {
        status: status.toUpperCase(),
        codeBroker: codeBroker,
      },
      headers: {
        "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
      },
    });

    const responseData = res.data;
    const totalTransaction = responseData.total;

    return totalTransaction;
  }

  const queries = useQueries({
    queries: [
      {
        queryKey: ["totalTransaction", "pending"],
        queryFn: () => fetchTotalTransaction("pending"),
      },
      {
        queryKey: ["totalTransaction", "approved"],
        queryFn: () => fetchTotalTransaction("approved"),
      },
      {
        queryKey: ["totalTransaction", "reject"],
        queryFn: () => fetchTotalTransaction("reject"),
      },
      {
        queryKey: ["totalTransaction", "goodfund"],
        queryFn: () => fetchTotalTransaction("goodfund"),
      },
    ],
  });

  const pending = queries[0].data;
  const goodfund = queries[3].data;
  const approved = queries[1].data;
  const reject = queries[2].data;

  return (
    <Stack gap={2.5}>
      <Typography variant="h2" fontSize={16} fontWeight={600}>
        Total Transaction
      </Typography>
      <CardsGridContainer>
        <CardsGridItem
          link="/transactions?status=pending"
          title="Pending"
          value={pending}
          icon={<PaidRoundedIcon color="primary" fontSize="large" />}
          active={status === "pending"}
        />
        <CardsGridItem
          link="/transactions?status=goodfund"
          title="Goodfund"
          value={goodfund}
          icon={<PaidRoundedIcon color="primary" fontSize="large" />}
          active={status === "goodfund"}
        />
        <CardsGridItem
          link="/transactions?status=approved"
          title="Approved"
          value={approved}
          icon={<PaidRoundedIcon color="primary" fontSize="large" />}
          active={status === "approved"}
        />
        <CardsGridItem
          link="/transactions?status=reject"
          title="Reject"
          value={reject}
          icon={<PaidRoundedIcon color="primary" fontSize="large" />}
          active={status === "reject"}
        />
      </CardsGridContainer>
    </Stack>
  );
}
