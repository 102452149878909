import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { CounterCard } from "../organisms";
import { toast } from "sonner";
import axios from "axios";

interface counterCardProps {
  value: string;
  valueColor: string;
}

const valueToPercentage = (value: string) => {
  if (value === null) {
    return { value: "-", valueColor: "" };
  }
  return {
    value: `${parseFloat(value) > 0 ? "+" : ""}${parseFloat(
      value || ""
    ).toFixed(2)}%`,
    valueColor:
      parseFloat(value) > 0
        ? "#119761"
        : parseFloat(value) < 0
        ? "#D80027"
        : "",
  };
};

export const GraphFooter = () => {
  const url = `${process.env.REACT_APP_BASE_URL}/mcs/product-performance`;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [oneDay, setOneDay] = useState<counterCardProps>();
  const [oneMonth, setOneMonth] = useState<counterCardProps>();
  const [oneYear, setOneYear] = useState<counterCardProps>();
  const [inception, setInception] = useState<counterCardProps>();
  const [threeMonth, setThreeMonth] = useState<counterCardProps>();
  const [threeYear, setThreeYear] = useState<counterCardProps>();
  const [ytd, setYtd] = useState<counterCardProps>();
  const productCode = "BALANCED A";

  useEffect(() => {
    axios
      .get(`${url}?productCode=${productCode}`, {
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
      })
      .then(function (response) {
        const data = response.data;
        setYtd(valueToPercentage(data?.ytd));
        setOneDay(valueToPercentage(data?.oneDay));
        setOneMonth(valueToPercentage(data?.oneMonth));
        setThreeMonth(valueToPercentage(data?.threeMonth));
        setOneYear(valueToPercentage(data?.oneYear));
        setThreeYear(valueToPercentage(data?.threeYear));
        setInception(valueToPercentage(data?.inception));
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.message);
      });
  }, []);

  return (
    <Card elevation={0} square>
      <Grid container style={{ width: "100%" }}>
        <Grid item xs>
          <CounterCard
            title={"Return 1D"}
            value={oneDay?.value}
            valueColor={oneDay?.valueColor}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs>
          <CounterCard
            title={"Return 1M"}
            value={oneMonth?.value}
            valueColor={oneMonth?.valueColor}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs>
          <CounterCard
            title={"Return 3M"}
            value={threeMonth?.value}
            valueColor={threeMonth?.valueColor}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs>
          <CounterCard
            title={"Return YTD"}
            value={ytd?.value}
            valueColor={ytd?.valueColor}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs>
          <CounterCard
            title={"Return 1Y"}
            value={oneYear?.value}
            valueColor={oneYear?.valueColor}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs>
          <CounterCard
            title={"Return 3Y"}
            value={threeYear?.value}
            valueColor={threeYear?.valueColor}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs>
          <CounterCard
            title={"Since Inception"}
            value={inception?.value}
            valueColor={inception?.valueColor}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
