import React from "react";
import { Grid, Stack } from "@mui/material";
import { TableCustomer } from "./components";
import CounterCardsGrid from "../../organisms/counterCardsGrid";

export const Customer: React.FC = () => {
  return (
    <Stack direction="column" gap={6}>
      <CounterCardsGrid />

      <TableCustomer />
    </Stack>
  );
};
