import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import {
  Card,
  Container,
  Chip,
  IconButton,
  useTheme,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ColorModeContext } from "../../theme";
import { convertHexToRGBA, supabase } from "../../utils";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { User } from "@supabase/supabase-js";

export const Header = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        const currentUser = session?.user;
        setUser(currentUser);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    const signoutFn = supabase.auth.signOut();

    toast.promise(signoutFn, {
      loading: "Signing out...",
      success: () => {
        navigate("/auth/login");
        return "Successfully signed out.";
      },
      error: "Error signing out.",
    });
  };

  return (
    <Card elevation={0} square>
      <Container
        style={{
          minHeight: "70px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Chip
          label={
            <div
              style={{
                color: theme?.palette?.secondary?.main,
                fontSize: "110%",
              }}
            >
              Mega Capital Sekuritas
            </div>
          }
          style={{
            backgroundColor: convertHexToRGBA(
              theme?.palette?.secondary?.main,
              0.1
            ),
            fontWeight: "bolder",
          }}
          onClick={() => navigate("/")}
        />

        <Stack direction="row" spacing={3} alignItems="center">
          {matches && <Typography>{user?.email}</Typography>}

          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              size="small"
              color="secondary"
              style={{
                border: "1px solid rgba(128, 128, 128, 0.3)",
                borderRadius: "12px",
              }}
              onClick={colorMode.toggleColorMode}
            >
              {theme?.palette?.mode === "light" ? (
                <DarkModeOutlined />
              ) : (
                <LightModeOutlined />
              )}
            </IconButton>

            <IconButton
              size="small"
              color="secondary"
              style={{
                paddingInline: "8px",
                border: "1px solid rgba(128, 128, 128, 0.3)",
                borderRadius: "12px",
                fontWeight: "bolder",
              }}
              onClick={handleLogout}
            >
              <LogoutOutlined style={{ marginRight: "5px" }} />
              Logout
            </IconButton>
          </Stack>
        </Stack>
      </Container>
    </Card>
  );
};
