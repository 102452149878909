import React, { useState, useEffect } from "react";
import { GraphHeader } from "../../../molecules";
import { Card, useMediaQuery } from "@mui/material";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { toast } from "sonner";
import dayjs from "dayjs";
import axios from "axios";

interface graphData {
  navDate: Date;
  navValue: number;
  aum?: string;
}

export const Graph: React.FC = () => {
  const url = `${process.env.REACT_APP_BASE_URL}/mcs/product-nav`;
  const productCode = "BALANCED A";
  const [data, setData] = useState<graphData[]>();
  const [highestNav, setHighestNav] = useState<number>(
    Number.POSITIVE_INFINITY
  );
  const [lowestNav, setLowestNav] = useState<number>(Number.NEGATIVE_INFINITY);
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    axios
      .get(`${url}?productCode=${productCode}`, {
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
      })
      .then(function (response) {
        setHighestNav(
          Math.max(...response.data.map((item: graphData) => item.navValue))
        );
        setLowestNav(
          Math.min(...response.data.map((item: graphData) => item.navValue))
        );
        setData(response.data);
      })
      .catch(function (error) {
        toast.error(error.message);
      });
  }, []);
  return (
    <Card elevation={0} style={{ paddingBottom: "24px" }}>
      <GraphHeader />
      <ResponsiveContainer width="100%" height={matches ? 400 : 200}>
        <LineChart data={data}>
          <Line
            type="monotone"
            dataKey="navValue"
            stroke="#119761"
            strokeWidth={3}
            dot={false}
          />
          <XAxis
            dataKey={"navDate"}
            axisLine={false}
            tickLine={false}
            style={{
              fontWeight: "bold",
            }}
            key={"key"}
            tickFormatter={(date) => dayjs(date).format("D MMM YYYY")}
            tick={{ dx: -50 }}
            fontSize={matches ? 12 : 10}
            interval={matches ? 4 : 8}
            allowDuplicatedCategory={false}
          />
          <YAxis type="number" domain={[lowestNav, highestNav]} hide />
          <Tooltip
            cursor={false}
            labelStyle={{ color: "black" }}
            labelFormatter={(date) => {
              return `Date: ${dayjs(date).format("D MMM YYYY")}`;
            }}
            formatter={(data) => {
              const nav = Number(data).toFixed(4)
                .toString()
                .replace(/\d(?=(\d{3})+\.)/g, '$&,');
              return [nav, "NAV"];
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};
