import React, { useState, useEffect } from "react";
import { Card, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import { CounterCard } from "../organisms";
import { toast } from "sonner";
import dayjs from "dayjs";
import axios from "axios";
import logo from "../../public/logo.png";

interface counterCardProps {
  value?: string;
  description?: string;
  subscript?: string;
  subscriptColor?: string;
}

export const GraphHeader = () => {
  const dataUrl = `${process.env.REACT_APP_BASE_URL}/mcs/product-nav`;
  const nameUrl = `${process.env.REACT_APP_BASE_URL}/mcs/product-detail`;
  const productCode = "BALANCED A";
  const [navData, setNavData] = useState<counterCardProps>();
  const [aumData, setAumData] = useState<counterCardProps>();
  const [productName, setProductName] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    axios
      .get(`${dataUrl}?productCode=${productCode}`, {
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
      })
      .then(function (response) {
        const latestData = response.data[response.data.length - 1];
        const dayBeforeData = response.data[response.data.length - 2];
        setNavData({
          value: `${parseFloat(latestData?.navValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          description: dayjs(latestData?.navDate).format("DD MMM YYYY"),
          subscript: `${parseFloat(
            (
              parseFloat(latestData?.navValue) -
              parseFloat(dayBeforeData?.navValue)
            ).toString()
          ).toFixed(2)} (${parseFloat(
            (
              ((parseFloat(latestData?.navValue) -
                parseFloat(dayBeforeData?.navValue)) *
                100) /
              parseFloat(dayBeforeData?.navValue)
            )
              .toString()
              .replace("-", "")
          ).toFixed(2)}%)`,
          subscriptColor:
            parseFloat(latestData?.navValue) -
              parseFloat(dayBeforeData?.navValue) >
            0
              ? "#119761"
              : parseFloat(latestData?.navValue) -
                  parseFloat(dayBeforeData?.navValue) <
                0
              ? "#D80027"
              : "",
        });
        setAumData({
          value: `${parseFloat(
            (parseFloat(latestData?.aum) / 1000000000).toString()
          ).toFixed(2)}M`,
          subscript: `${parseFloat(
            (
              (parseFloat(latestData?.aum) - parseFloat(dayBeforeData?.aum)) /
              1000000000
            ).toString()
          ).toFixed(2)}M (${parseFloat(
            (
              ((parseFloat(latestData?.aum) - parseFloat(dayBeforeData?.aum)) *
                100) /
              parseFloat(dayBeforeData?.aum)
            )
              .toString()
              .replace("-", "")
          ).toFixed(2)}%)`,
          subscriptColor:
            parseFloat(latestData?.aum) - parseFloat(dayBeforeData?.aum) > 0
              ? "#119761"
              : parseFloat(latestData?.aum) - parseFloat(dayBeforeData?.aum) < 0
              ? "#D80027"
              : "",
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.message);
      });
    axios
      .get(`${nameUrl}?productCode=${productCode}`, {
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
      })
      .then(function (response) {
        setProductName(response.data?.productName);
      })
      .catch(function (error) {
        toast.error(error.message);
      });
  }, []);

  return (
    <Card elevation={0} square>
      <div style={{ backgroundColor: "#255AA1", height: "20px" }}></div>
      <Grid container style={{ padding: "0px 0px 0px 20px", width: "100%" }}>
        <Grid
          item
          style={{
            alignSelf: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: matches ? 60 : 40,
              width: matches ? 60 : 40,
              borderRadius: "4px",
              marginTop: matches ? "6px" : "14px",
              marginRight: "4px",
            }}
            src={logo}
          />
        </Grid>
        <Grid
          item
          style={{
            alignSelf: "center",
            marginLeft: "10px",
            marginTop: matches ? "" : "8px",
          }}
          xs={matches ? 6 : 8}
        >
          <Typography
            noWrap
            fontSize={matches ? 20 : 13}
            fontWeight="700"
            lineHeight={1}
          >
            Reksa Dana <br></br> {productName}
          </Typography>
        </Grid>
        <Grid item xs style={{ marginLeft: matches ? "" : "-10px" }}>
          <CounterCard
            title={"NAV/Unit"}
            value={navData?.value}
            subscript={navData?.subscript}
            subscriptColor={navData?.subscriptColor}
            isLoading={isLoading}
            description={navData?.description}
          />
        </Grid>
        <Grid item xs>
          <CounterCard
            title={"AUM"}
            value={aumData?.value}
            subscript={aumData?.subscript}
            subscriptColor={aumData?.subscriptColor}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
